import React, {useState, useEffect} from 'react';

import {Link} from 'react-router-dom';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Grid from '@material-ui/core/Grid';
import Avatar from '@material-ui/core/Avatar';
import DomainIcon from '@material-ui/icons/Domain';
import LabelIcon from '@material-ui/icons/Label';
import SettingsIcon from '@material-ui/icons/Settings';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import CreateIcon from '@material-ui/icons/Create';
import MemoryIcon from '@material-ui/icons/Memory';
import BarChartIcon from '@material-ui/icons/BarChart';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import CodeIcon from '@material-ui/icons/Code';
import MessageIcon from '@material-ui/icons/Message';
import InfoIcon from '@material-ui/icons/Info';
import ModelTrainingIcon from '@material-ui/icons/Refresh';
import ListIcon from '@material-ui/icons/ViewList';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import Collapse from '@material-ui/core/Collapse';
import Tooltip from '@material-ui/core/Tooltip';
import DataUseIcon from '@material-ui/icons/DataUsage';
import Typography from '@material-ui/core/Typography';
import withStyle from '../style';

import Networker from '../Utils/Networker';

const LinkRef = React.forwardRef((props, r) => {
  const ref = React.createRef();
  return <Link {...props} innerRef={ref}/>;
});

const getAllSUProducts = (org) => {
  return Networker.get({
    root: 'products',
    inner: 'all',
    cache: true
  });
};

const getAllAdminProducts = () => {
  return Networker.get({
    root: 'products',
    inner: 'admin',
    cache: true
  });
};

const getAllSUOrganizations = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'all',
    cache: true
  });
};

const getAllAdminOrganiztions = () => {
  return Networker.get({
    root: 'organizations',
    inner: 'admin',
    cache: true
  });
};

export function MainListItems({match}) {
  const [superUser, setSuper] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    Networker.getUserSession()
    .then(async res => {
      const {isSuperUser} = res.body;
      if (isSuperUser) {
        setSuper(true);
        setIsAdmin(true);
      } else {
        try {
          const orgs = await getAllAdminOrganiztions();
          setIsAdmin(orgs.body.length > 0);
        } catch (e) {
          if (e.status === 401) {
            setIsAdmin(false);
          }
        }
      }
    });
  }, []);

   return <List>
      <ListSubheader>{superUser ? 'Super ' : ''}
        {isAdmin ? 'Admin' : 'User'}
      </ListSubheader>
      {isAdmin ? <ListItem button to="/products" component={LinkRef}
        dense
        selected={match.path.startsWith('/products')}>
        <ListItemIcon>
          <DeviceHubIcon />
        </ListItemIcon>
        <ListItemText primary="Products" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/middleware" component={LinkRef}
        dense
        selected={match.path.startsWith('/middleware')}>
        <ListItemIcon>
          <CodeIcon />
        </ListItemIcon>
        <ListItemText primary="Middleware" />
      </ListItem> : null}
      {isAdmin ? <ListItem button to="/organizations" component={LinkRef}
        dense
        selected={match.path.startsWith('/organizations')}>
        <ListItemIcon>
          <DomainIcon />
        </ListItemIcon>
        <ListItemText primary="Organizations" />
      </ListItem> : null}
      <ListItem button to="/account" component={LinkRef}
        dense
        selected={match.path.startsWith('/account')}>
        <ListItemIcon>
          <SettingsIcon />
        </ListItemIcon>
        <ListItemText primary="Account" />
      </ListItem>
      {superUser ? <ListItem button to="/logs" component={LinkRef}
        dense
        selected={match.path.startsWith('/logs')}>
        <ListItemIcon>
          <ListIcon />
        </ListItemIcon>
        <ListItemText primary="Logs" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/admin" component={LinkRef}
        dense
        selected={match.path.startsWith('/admin')}>
        <ListItemIcon>
          <DataUseIcon />
        </ListItemIcon>
        <ListItemText primary="Admin" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/sensors" component={LinkRef}
        dense
        selected={match.path.startsWith('/sensors')}>
        <ListItemIcon>
          <LabelIcon />
        </ListItemIcon>
        <ListItemText primary="Sensors" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/datacleaning" component={LinkRef}
        dense
        selected={match.path.startsWith('/datacleaning')}>
        <ListItemIcon>
          <VerifiedUserIcon />
        </ListItemIcon>
        <ListItemText primary="Data Cleaning" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/calibration" component={LinkRef}
        dense
        selected={match.path.startsWith('/calibration')}>
        <ListItemIcon>
          <MemoryIcon />
        </ListItemIcon>
        <ListItemText primary="Calibration" />
      </ListItem> : null}
      {superUser ? <ListItem button to="/simulator" component={LinkRef}
        dense
        selected={match.path.startsWith('/simulator')}>
        <ListItemIcon>
          <ModelTrainingIcon />
        </ListItemIcon>
        <ListItemText primary="Simulator" />
      </ListItem> : null}
    </List>;
}


export function DynamicListItems({match, open}) {
  const [expanded, setExpanded] = useState('');
  const [organizationProductMap, setProductMap] = useState({});


  useEffect(() => {
    Networker.getUserSession()
    .then(async res => {
      const {isSuperUser} = res.body;
      if (isSuperUser) {
        const allOrgs = await getAllSUOrganizations();
        const allProds = await getAllSUProducts();
        const productMap = {};
        allOrgs.body
          .sort((a,b) => a.name > b.name ? 0 : -1)
          .forEach(org => {
          productMap[org._id] = {
            ...org,
            products: allProds.body
              .filter(prod => (prod.organizations || []).includes(org._id))
              .sort((a,b) => a.name > b.name ? 0 : -1)
          };
        });
        setProductMap(productMap);
        setExpanded(match.params.orgId);
      } else {
        try {
          const allOrgs = await getAllAdminOrganiztions();
          const allProds = await getAllAdminProducts();
          const productMap = {};
          allOrgs.body
          .sort((a,b) => a.name > b.name ? 0 : -1)
          .forEach(org => {
            productMap[org._id] = {
              ...org,
              products: allProds.body
              .filter(prod => (prod.organizations || []).includes(org._id))
              .sort((a,b) => a.name > b.name ? 0 : -1)
            };
          });
          setProductMap(productMap);
          setExpanded(match.params.orgId);
        } catch (e) {
          if (e.status === 401) {
            console.log('user is not an admin');
          }
        }
      }
    })
    .catch(err => {
      console.error(err);
      window.alert('error processing request');
    })
  }, [match.params.orgId]);

  const onExpand = (e) => {
    let node = e.target;
    let i = 0;
    while (node.id === '' && i < 4) {
      node = node.parentNode;
      i += 1;
    }
    const orgId = node.id;

    setExpanded(orgId === expanded ? '' : orgId);
  };

  const classes = withStyle();
  return (<List component='nav' dense>
    {Object.entries(organizationProductMap).map(([
      orgId, {products, iconURL, name, tier
      }]) => {
        const prods = products;
        const selected = match.url.startsWith(`/organization/${orgId}`);
      return prods.length > 0 ? <React.Fragment key={orgId}>
        <ListSubheader style={{backgroundColor: 'white'}} disableGutters>
          <Grid container alignItems="center" justifyContent="flex-start">
            <Grid item xs={3}>
              <IconButton
                style={{paddingLeft: 10}}
                size="small"
                id={orgId}
                onClick={onExpand}>
                <Avatar src={iconURL} alt={orgId.substr(0,2)}/>
              </IconButton>
            </Grid>
            <Grid item xs={7}>
              <Typography onClick={onExpand}
                id={orgId}
                noWrap
                variant="subtitle2"
                style={{cursor: 'pointer'}}
                color="textPrimary">
                {name}
              </Typography>
                <Typography variant="body2"
                  style={{cursor: 'pointer'}}
                  noWrap
                  onClick={onExpand}
                  color="textSecondary">
                  Tier: {tier}
                </Typography>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                disabled={selected}
                to={`/organization/${orgId}`}
                component={LinkRef}
                size='small'>
                <InfoIcon color="primary"/>
              </IconButton>
            </Grid>
          </Grid>
        </ListSubheader>
        <Collapse in={orgId === expanded} timeout="auto">
          <List disablePadding dense>
            {prods.map(prod => {
              const title = `${prod.name} [${prod.productId}]`;
                return <ListItem key={prod._id}
                  alignItems="center" justifyContent="space-around"
                  divider
                  className={classes.productListItem}
                  selected={match.url.startsWith(`/dashboard/${orgId}/${prod._id}`)}
                  button to={`/dashboard/${orgId}/${prod._id}`}
                  component={LinkRef}>
                  <Tooltip title={title}>
                  <ListItemAvatar>
                    <Avatar size="small" src={prod.iconURL} alt={prod.name.substr(0,2)}/>
                  </ListItemAvatar>
                </Tooltip>
            <ListItemText
              primaryTypographyProps={
                {variant:'body2', noWrap: true}
              }
              primary={prod.name}
              secondary={'ID: ' + prod.productId}/>
          </ListItem>
            })}
            {(products || []).length === 0 ? <ListItem>
              No products registered
            </ListItem> : null}
          </List>
        </Collapse>
      </React.Fragment>: null
    })}
  </List>);
}

export function SecondaryListItems({match}) {
  const [isAdmin, setIsAdmin] = useState(false);
  const [hasConnectionStatsAccess, setHasConnectionStatsAccess] = useState(false);
  const [isSuperUser, setIsSuperUser] = useState(false);
  
  useEffect(() => {
    Networker.getUserSession()
    .then(async res => {
      setIsSuperUser(res.body.isSuperUser);
      if (res.body.isSuperUser) {
        setIsAdmin(true);
      } else {
        try {
          const orgs = await getAllAdminOrganiztions();
          setIsAdmin(orgs.body.length > 0);
          for(const org of orgs.body){
            if(org.connectionStatsAccess){
              setHasConnectionStatsAccess(true);
              break;
            }
          }
        } catch (e) {
          if (e.status === 401) {
            setIsAdmin(false);
          }
        }
      }
    });
  }, []);
  return isAdmin ? <List>
      <ListSubheader>
        Utilities
      </ListSubheader>
      <ListItem button to="/messages" component={LinkRef}
        dense
        selected={match.path.startsWith('/messages')}>
        <ListItemIcon>
          <MessageIcon />
        </ListItemIcon>
        <ListItemText primary="Consumer Connect" />
      </ListItem>
      
      {!hasConnectionStatsAccess && !isSuperUser ? null : <ListItem button to="/connectionstatistics" component={LinkRef}
        dense
        selected={match.path.startsWith('/connectionstatistics')}>
        <ListItemIcon>
          <AccountTreeIcon />
        </ListItemIcon>
        <ListItemText primary="Connection Statistics" />
      </ListItem>}

      <ListItem button to="/volumetric" component={LinkRef}
        dense
        selected={match.path.startsWith('/volumetric')}>
        <ListItemIcon>
          <BarChartIcon />
        </ListItemIcon>
        <ListItemText primary="Demo" />
      </ListItem>
      <ListItem button to="/dataentry" component={LinkRef}
        dense
        selected={match.path.startsWith('/dataentry')}>
        <ListItemIcon>
          <CreateIcon />
        </ListItemIcon>
        <ListItemText primary="Data Entry" />
      </ListItem>
  </List> : null;
}
