import React from 'react';
import '../css/App.css';

import Dashboard from './Container/Dashboard/Dashboard';
import Login from './Container/Login';
import Register from './Container/Register';
import Products from './Container/Products';
import Organizations from './Container/Organizations';
import Middleware from './Container/Middleware';
import Account from './Container/Account';
import VolumetricDemo from './Container/VolumetricDemo';
import Logs from './Container/Logs';
import Admin from './Container/Admin';
import Sensors from './Container/Sensors';
import ConsumerConnect from './Container/ConsumerConnect';
import ConnectionStatistics from './Container/ConnectionStatistics';
import OrganizationInfo from './Container/OrganizationInfo';
import ResetPassword from './Container/ResetPassword';
import EmailCheck from './Container/EmailCheck';
import DataEntry from './Container/DataEntry';
import Privacy from './Container/Privacy';
import Terms from './Container/Terms';
import DataCleaning from './Container/DataCleaning';
import CleaningPage from './Container/CleaningPage';
import Calibration from './Container/Calibration';
import Simulator from './Container/Simulator';

import ErrorBoundary from './Components/ErrorBoundary';

import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";

import { ThemeProvider } from '@material-ui/styles';
import {theme} from './style';
import AppEcom from './Container/AppEcom';



function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <div>
          <ErrorBoundary>
          <Switch>
            <Route path="/register" component={Register}>
            </Route>
            <Route path="/login" component={Login}>
            </Route>
            <Route path="/resetpassword/:token" component={ResetPassword}>
            </Route>
            <Route path="/recover" component={EmailCheck}>
            </Route>
            <Route path="/organization/:orgId" component={OrganizationInfo}>
            </Route>
            <Route path="/dashboard/:orgId/:productId" component={Dashboard}>
            </Route>
            <Route path="/products" component={Products}>
            </Route>
            <Route path="/middleware" component={Middleware}>
            </Route>
            <Route path="/organizations" component={Organizations}>
            </Route>
            <Route path="/account" component={Account}>
            </Route>
            <Route path="/volumetric" component={VolumetricDemo}>
            </Route>
            <Route path="/messages/:productId?" component={ConsumerConnect}>
            </Route>
            <Route path="/logs" component={Logs}>
            </Route>
            <Route path="/admin" component={Admin}>
            </Route>
            <Route path="/sensors" component={Sensors}>
            </Route>
            <Route path="/datacleaning/:datasetId" component={CleaningPage}>
            </Route>
            <Route path="/datacleaning" component={DataCleaning}>
            </Route>
            <Route path="/calibration" component={Calibration}>
            </Route>
            <Route path="/simulator" component={Simulator}>
            </Route>
            <Route path="/dataentry" component={DataEntry}>
            </Route>
            <Route path="/connectionstatistics/:productId?" component={ConnectionStatistics}>
            </Route>
            <Route path="/privacy" component={Privacy}>
            </Route>
            <Route path="/terms" component={Terms}>
            </Route>
              <Route path="/app/ecom/:userId/:productId/:providerId" component={AppEcom}>
              </Route>
            <Route>
              <Redirect to="/login"></Redirect>
            </Route>
          </Switch>
          </ErrorBoundary>
        </div>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
