import React, { useState, useEffect  } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Grid, Paper, List, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, Button } from '@material-ui/core';
import Networker from '../Utils/Networker'; // Assuming you are using this utility for API requests
import AppContainer from '../Components/AppContainer';
import MadeWithLove from '../Components/MadeWithLove';
import withStyle from '../style';

export default function Simulator({ match }) {
  const classes = withStyle();

  // State variables for projects and filtering
  const [projects, setProjects] = useState([]);
  const [projectNameFilter, setProjectNameFilter] = useState('');
  const [showProjectList, setShowProjectList] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null); // eslint-disable-line no-unused-vars
  const [simulators, setSimulators] = useState([]);

  const isEmptyOrNull = (obj) => {
    if (obj === null || obj === undefined) {
      return true; // Object is null or undefined
    }

    return Object.keys(obj).length === 0; // Check if the object has any keys
  };

  // Fetch projects on component mount
  useEffect(() => {
    const loadProjects = () => {
      Networker.getFromTraining({
        root: 'training',
        inner: 'project/all',
        cache: true,
      }).then(({ body }) => {
        setProjects(body); // Sort based on project names
      }).catch((err) => {
        console.error(err);
      });
    };

    loadProjects();
  }, []);

  const fetchSimulators = async() => {
    if (!selectedProject) return;
    try {
      const { body } = await Networker.getFromTraining({
        root: 'training',
        inner: `simulator/${selectedProject}`,
        query: null
      });
      setSimulators(body.file_names);
    } catch (err) {
      console.error(err);
    }
  };
  // Handle project click
  const handleProjectClick = (project) => async () => {
    if (isEmptyOrNull(project)) {
      alert('Please select a project first');
      return;
    }
    setProjectNameFilter(project); // Set the selected project name in the search bar
    setSelectedProject(project); // Store the selected project in state
    setShowProjectList(false); // Optionally hide the project list after selection
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setProjectNameFilter(e.target.value);
  };

  // Show project list when input is focused
  const handleFilterFocus = () => {
    setShowProjectList(true);
  };

  return (
      <div className={classes.root}>
        <CssBaseline />
        <AppContainer classes={classes} title="Simulator" match={match}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            {/* Filter input */}
            <TextField
                id="search-project"
                label="Search Projects"
                variant="outlined"
                fullWidth
                value={projectNameFilter}
                onChange={handleFilterChange}
                onFocus={handleFilterFocus}
                style={{ marginBottom: '20px' }}
            />

            {/* Buttons: Simulator */}
            <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
              <Button
                  id="simulator-button"
                  variant="contained"
                  style={{ backgroundColor: 'light-grey', color: 'black', borderRadius: '20px', flex: 1 }}
                  onClick={fetchSimulators}
              >
                Simulator
              </Button>
            </div>

            {showProjectList && (
                <Grid item xs={12} md={6} lg={4}>
                  <Paper>
                    <List>
                      {projects
                          .filter((name) => name.toLowerCase().includes(projectNameFilter.toLowerCase()))
                          .map((project) => (
                              <ListItem key={project} button divider dense onClick={handleProjectClick(project)}>
                                <ListItemAvatar>
                                  <Avatar alt={project.substr(0, 3)} /> {/* Removed project icon URL since it's not provided */}
                                </ListItemAvatar>
                                <ListItemText primary={project} />
                              </ListItem>
                          ))}
                    </List>
                  </Paper>
                </Grid>
            )}
            {/* Display simulators if available */}
            {selectedProject && simulators.length > 0 && (
                <Grid item xs={12} md={6} lg={4}>
                  <Paper style={{ marginTop: '20px' }}>
                    <List>
                      {simulators.map((simulator) => (
                          <ListItem key={simulator} divider>
                            <ListItemText primary={simulator} />
                          </ListItem>
                      ))}
                    </List>
                  </Paper>
                </Grid>
            )}
          </Container>
          <MadeWithLove />
        </AppContainer>
      </div>
  );
}
