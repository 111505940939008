import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Container, Grid, Paper, List, Typography, ListItem, ListItemAvatar, ListItemText, Avatar, TextField, Button } from '@material-ui/core';
import Networker from '../Utils/Networker'; // Assuming you are using this utility for API requests
import AppContainer from '../Components/AppContainer';
import MadeWithLove from '../Components/MadeWithLove';
import withStyle from '../style';
import Delete from '@material-ui/icons/Delete';

export default function Calibration({ match }) {
  const classes = withStyle();

  // State variables for products and filtering
  const [products, setProducts] = useState([]);
  const [productNameFilter, setProductNameFilter] = useState('');
  const [selectedProduct, setSelectedProduct] = useState(null); // New state for selected product
  const [showProductList, setShowProductList] = useState(false);
  const [configData, setConfigData] = useState(null); // State for fetched configuration data

  // this is for local state when we update the parameter key
  const [keyStates, setKeyStates] = useState({}); // Initially, an empty object
  // Update keyStates when configData becomes available or changes
  useEffect(() => {
    if (configData) {
      const initialKeyStates = Object.keys(configData).reduce((acc, key) => {
        acc[key] = key; // Create a state for each key
        return acc;
      }, {});
      setKeyStates(initialKeyStates); // Set the new key states
    }
  }, [configData]); // This will run whenever configData changes

  const availableTypes = ['number', 'string', 'boolean'];

  const isEmptyOrNull = (obj) => {
    if (obj === null || obj === undefined) {
      return true; // Object is null or undefined
    }

    return Object.keys(obj).length === 0; // Check if the object has any keys
  }

  // Fetch products on component mount
  useEffect(() => {
    const loadProducts = () => {
      Networker.get({
        root: 'products',
        inner: 'all',
        cache: true,
      }).then(({ body }) => {
        setProducts(body.sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
      }).catch((err) => {
        console.error(err);
      });
    };

    loadProducts();
  }, []);

  // Handle product click
  const handleProductClick = (product) => () => {
    setProductNameFilter(product.name); // Set the selected product's name in the search bar
    setSelectedProduct(product); // Store the selected product in state
    setShowProductList(false); // Optionally hide the product list after selection
  };

  // Handle filter change
  const handleFilterChange = (e) => {
    setProductNameFilter(e.target.value);
  };
  // Save configuration data
  const handleSaveConfiguration = () => {
    if (isEmptyOrNull(selectedProduct) || isEmptyOrNull(configData)) {
      alert('Please select a product and configure parameters first');
      return;
    }

    const productId = selectedProduct.productId;

    Networker.put({
      root: 'labels',
      inner: 'config',
      query: {
        productId: productId
      },
      body: configData
    }).then(({ body }) => {
      console.log('Configuration saved successfully:', body);
      alert('Configuration saved successfully');
    }).catch((err) => {
      console.error('Error saving configuration:', err);
      const errorBody = err.response.body
      alert(`Error saving configuration with error: ${errorBody.error}, Details: ${errorBody.details}`);
    });
  };

  // Show product list when input is focused
  const handleFilterFocus = () => {
    setShowProductList(true);
  };

  const [newParameter, setNewParameter] = useState({ key: '', value: '', type: 'string' });

  // Modified function to handle adding a new parameter
  const handleAddParameter = () => {
    if (newParameter.key.trim() === '') {
      alert('Please enter a parameter name');
      return;
    }
    setConfigData(prev => ({
      ...prev,
      [newParameter.key]: { value: newParameter.value, type: newParameter.type }
    }));
    setNewParameter({ key: '', value: '', type: 'string' }); // Reset the new parameter state
  };
  const handleNewParameterChange = (field) => (event) => {
    setNewParameter(prev => ({ ...prev, [field]: event.target.value }));
  };
  // Function to delete a parameter
  const handleDeleteParameter = (key) => {
    setConfigData(prev => {
      const newData = { ...prev };
      delete newData[key];
      return newData;
    });
  };

  //


  // Handle configuration button click
  const handleConfigurationClick = () => {
    if (isEmptyOrNull(selectedProduct)) {
      alert('Please select a product first');
      return;
    }
    

    // Fetch configuration data for the selected product
    const productId = selectedProduct.productId; // Assuming the product has an '_id' field

    // Make an API call to the config endpoint
    Networker.get({
      root: 'labels',
      inner: 'config',
      query: {
        productId: productId
      },
      cache: true,
    }).then(({ body }) => {
      const modifiableFields = _.omitBy(body, field => field.isModifiable === false);
      setConfigData(modifiableFields); // Store the fetched data in state
      console.log('Configuration data:', modifiableFields);
    }).catch((err) => {
      console.error('Error fetching configuration:', err);
    });
  };
  const handleParameterChange = (key, field) => (event) => {
    setConfigData(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [field]: event.target.value
      }
    }));
  };

  // Handle key changes locally without updating the main configData immediately
  const handleParameterNameKeyChange = (key, newKey) => {
    setKeyStates((prev) => ({
      ...prev,
      [key]: newKey,
    }));
  };

  // Update configData only when the input field loses focus
  const handleParameterNameBlur = (key) => {
    const newKey = keyStates[key];
    if (newKey !== key) {
      setConfigData((prev) => {
        const newData = { ...prev };
        newData[newKey] = newData[key]; // Set the new key
        delete newData[key]; // Remove the old key
        return newData;
      });
    }
  };
  
  const renderConfigParameters = () => {
    if (isEmptyOrNull(configData)) return null;
  
    return (
      <Paper style={{ padding: '20px', marginTop: '20px' }}>
        <Typography variant="h6" gutterBottom>
          Configuration Parameters
        </Typography>
        
        {Object.entries(configData).map(([key, { value, type }]) => (
          <Grid container spacing={2} key={key} style={{ marginBottom: '10px' }}>
            <Grid item xs={3}>
              <TextField
                id={`param-name-${key}`}
                variant="outlined"
                label="Parameter"
                value={keyStates[key] || key} // Controlled input from the local key state
                onChange={(e) => handleParameterNameKeyChange(key, e.target.value)} // Update local state as user types
                onBlur={() => handleParameterNameBlur(key)} // Update configData when the field loses focus
                fullWidth
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                id={`param-type-${key}`}
                select
                variant="outlined"
                label="Type"
                value={type}
                onChange={handleParameterChange(key, 'type')}
                SelectProps={{
                  native: true,
                }}
                fullWidth
              >
                {availableTypes.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id={`param-value-${key}`}
                variant="outlined"
                label="Value"
                value={value}
                onChange={handleParameterChange(key, 'value')}
                fullWidth
              />
            </Grid>
            <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <Delete
              id={`delete-param-button-${key}`}
              onClick={() => handleDeleteParameter(key)}
              style={{ cursor: 'pointer', color: 'grey' }}
            />
            </Grid>
          </Grid>
        ))}
        
        <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
          Add New Parameter
        </Typography>
        
        <Grid container spacing={2} style={{ marginBottom: '10px' }}>
          <Grid item xs={3}>
            <TextField
              id="new-param-name"
              variant="outlined"
              label="New Parameter"
              value={newParameter.key}
              onChange={handleNewParameterChange('key')}
              fullWidth
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="new-param-type"
              select
              variant="outlined"
              label="New Type"
              value={newParameter.type}
              onChange={handleNewParameterChange('type')}
              SelectProps={{
                native: true,
              }}
              fullWidth
            >
              {availableTypes.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={3}>
            <TextField
              id="new-param-value"
              variant="outlined"
              label="New Value"
              value={newParameter.value}
              onChange={handleNewParameterChange('value')}
              fullWidth
            />
          </Grid>
          <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            <Button
              id="add-param-button"
              variant="contained"
              color="primary"
              onClick={handleAddParameter}
              fullWidth
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Button
          id="save-config-button"
          variant="contained"
          color="primary"
          onClick={handleSaveConfiguration}
          style={{ marginTop: '20px' }}
        >
          Save Configuration
        </Button>
      </Paper>
    );
  };
  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppContainer classes={classes} title="Calibration" match={match}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {/* Filter input */}
          <TextField
            id="search-product"
            label="Search Products"
            variant="outlined"
            fullWidth
            value={productNameFilter}
            onChange={handleFilterChange}
            onFocus={handleFilterFocus}
            style={{ marginBottom: '20px' }}
          />

          {/* Buttons: Configuration*/}
          <div style={{ display: 'flex', gap: '10px', marginBottom: '20px' }}>
            <Button
              id="config-button"
              variant="contained"
              style={{ backgroundColor: 'light-grey', color: 'black', borderRadius: '20px', flex: 1 }}
              onClick={handleConfigurationClick} // Configuration button click handler
            >
              Get Configuration
            </Button>
          </div>

          {showProductList && (
            <Grid item xs={12} md={6} lg={4}>
              <Paper>
                <List>
                  {products.filter(({ name }) => name.toLowerCase().includes(productNameFilter.toLowerCase())).map((product) => (
                    <ListItem key={product._id} button divider dense onClick={handleProductClick(product)}>
                      <ListItemAvatar>
                        <Avatar alt={product.name.substr(0, 3)} src={product.iconURL} />
                      </ListItemAvatar>
                      <ListItemText primary={product.name} />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Grid>
          )}

          {/* Display fetched configuration data */}
          {configData && (
            <div style={{ marginTop: '20px' }}>
              <h3>Configuration Data</h3>
              {renderConfigParameters()}
            </div>
          )}
        </Container>
        <MadeWithLove />
      </AppContainer>
    </div>
  );
}
